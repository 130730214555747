<template>
  <div
    :class="[
      'UIElement',
      $style.GlobalNav,
      showSaveBar && $style.hasSaveBar
    ]"
    :data-theme="_theme"
  >
    <transition
      :enter-active-class="$style.SaveBarFade_enterActive"
      :leave-active-class="$style.SaveBarFade_leaveActive"
      :enter-class="$style.SaveBarFade_enter"
      :leave-to-class="$style.SaveBarFade_leaveTo"
    >
      <SaveBar
        v-if="showSaveBar"
        :state="internalSaveBarState"
        :translations="translations.saveBar || {}"
        :layoutMode="layoutMode"
        @save="$emit('save')"
        @discard="$emit('discard')"
      ></SaveBar>
    </transition>
    <div :class="$style.GlobalNav_bar">
      <template v-if="logo">
        <img
          :class="$style.Logo__light"
          src="../../assets/logo-icon.svg"
        >
        <img
          :class="$style.Logo__dark"
          src="../../assets/logo-icon-negative.svg"
        >
      </template>
      <div
        v-if="hasPrefixTitleActions || _title"
        :class="[
          $style.TitleWrapper,
          logo && $style.TitleWrapper__withLogo
        ]"
      >
        <template v-if="hasPrefixTitleActions">
          <template
            v-for="(action, index) in _prefixTitleActions"
          >
            <div
              v-if="true === true"
              :key="`action-${index}`"
              :class="[
                $style.Title,
                $style.Title__button
              ]"
              @click="action.onAction"
            >
              <Icon
                v-if="action.icon"
                :icon="action.icon"
                :class="[
                  $style.Title__icon,
                  action.label && $style.Title__icon__withLabel
                ]"
              />
              {{action.label}}
            </div>
            <div
              :key="`sep-${index}`"
              v-if="_prefixTitleSeparator && (index < _prefixTitleActions.length || _title)"
              style="display: flex;"
            >
              <div :class="$style.TitleSeparator"></div>
            </div>
          </template>
        </template>
        <div
          :class="[
            $style.Title,
            hasClickTitleListener && $style.Title__button
          ]"
          @click="onClickTitle"
        >{{_title}}</div>
      </div>
      <div :class="$style.Search">
        <div :class="$style.SearchControl">
          <Icon
            icon="fa fa-search"
            :class="$style.Search_icon"
          />
          <input
            :class="$style.Search_input"
            placeholder="Search for services and resources by name"
          />
          <div :class="$style.SearchDropdown">
            asddf
          </div>
        </div>
      </div>
      <div :class="$style.Right">
        <ServicePicker
          v-if="_serviceSections.length"
          :sections="_serviceSections"
          :i18n="translations"
        />
        <Notifications
          v-if="_notifications"
          :items="_notifications"
          :i18n="translations"
        />
        <LanguageSwitcher
          v-if="_languages && _currentLanguage"
          :languages="_languages"
          :value="_currentLanguage"
          @change="onChangeLanguage"
        />
        <AccountSwitcher
          v-if="_account && _accounts.length"
          :accounts="_accounts"
          :value="_account.accountId"
          @change="onChangeAccount"
        />
      </div>
      <AccountNav
        v-if="_account"
        :account-name="_account.accountName"
        :user-name="_account.userName"
        :user-email="_account.userEmail"
        :avatar-url="_account.avatarUrl"
        :sections="_account.sections"
      />
    </div>
    <div
      v-if="progress > 0"
      :class="$style.ProgressBar"
      :style="`width: ${progress}%`"
    ></div>
    <EventDelegator
      @ui-progress="onUiProgress"
    />
  </div>
</template>

<script>
import {EventDelegator} from '../EventDelegator'
import {Icon} from '../Icon'
import AccountNav from './components/AccountNav'
import AccountSwitcher from './components/AccountSwitcher'
import LanguageSwitcher from './components/LanguageSwitcher'
import Notifications from './components/Notifications'
import SaveBar from './components/SaveBar'
import ServicePicker from './components/ServicePicker'
import saveBarStore from '../../api/saveBar'

const defaultTranslations = {
  services: 'Services',
  noNotifications: 'No notifications'
}

const themes = {
  dark: {
    '--ui-globalNav-color': '#fff',
    '--ui-globalNav-bgColor': '#232e3e',
    '--ui-globalNav-searchColor': '#fff',
    '--ui-globalNav-searchBgColor': '#4c4c4c',
    '--ui-globalNav-searchHoverBrColor': '#666',
    '--ui-globalNav-hoverBgColor': 'rgba(255, 255, 255, 0.3)'
  },
  light: {
    '--ui-globalNav-color': '#344463',
    '--ui-globalNav-bgColor': '#fff',
    '--ui-globalNav-searchColor': '#fff',
    '--ui-globalNav-searchBgColor': '#3c85eb',
    '--ui-globalNav-searchHoverBrColor': '#689fef',
    '--ui-globalNav-hoverBgColor': 'rgba(0, 0, 0, 0.1)'
  }
}

export default {
  components: {
    EventDelegator,
    Icon,
    AccountNav,
    AccountSwitcher,
    LanguageSwitcher,
    Notifications,
    SaveBar,
    ServicePicker
},
  props: {
    /**
     * The big options object. This allows most options to be privided as one
     * giant object to make it easier to share configurations between apps. The
     * other individual props are applied after this value. See `Options` type
     * for details.
     */
    options: {
      type: Object
    },
    /**
     * Denotes the current app/service/section of the portal. E.g. "Campaigns"
     */
    title: {
      type: String
    },
    /**
     * An array of actions to render before the title. E.g. a back button.
     */
    prefixTitleActions: {
      type: Array,
      required: false
    },
    /**
     * Whether to render a separator between the prefix title actions if more than one
     */
    prefixTitleSeparator: {
      type: Boolean,
      default: true
    },
    /**
     * Details of the current account/user
     */
    account: {
      type: Object
    },
    /**
     * An array of available accounts to choose in the account switcher. The
     * current account will be selected.
     */
    accounts: {
      type: Array
    },
    /**
     * The current selected language.
     */
    currentLanguage: {
      type: String
    },
    /**
     * An array of available languages to choose in the language switcher
     */
    languages: {
      type: Array
    },
    /**
     * Shows a dropdown with a notification count
     */
    notifications: {
      type: Array
    },
    /**
     * An array of sections which group services/apps the user can navigate to
     * using the service picker.
     */
    serviceSections: {
      type: Array
    },
    /**
     * The theme to use. Supports `dark` and `light`.
     */
    theme: {
      type: String,
      default: 'dark'
    },
    /**
     * 'default':
     * 'alternate':
     */
    layoutMode: {
      type:String,
      default:'default'
    },
    /**
     * Wether to render logo. Default `true`.
     */
     logo: {
      type: Boolean,
      default: true
    },
    /**
     * Allows default English translations of the GlobalNav to be overridden.
     */
    i18n: {
      type: Object
    },
    /**
     * Controls the state of the save bar.
     *
     *  - `unchanged`: Save bar is hidden.
     *  - `changed`: Prompts the user to save or discard changes.
     *  - `saving`: Saving is in progress.
     */
    saveBarState: {
      type: String,
      default: 'unchanged',
      validator(value) {
        return ['unchanged', 'changed', 'saving'].indexOf(value) >= 0
      }
    }
  },
  data() {
    return {
      progress: 0,
      internalSaveBarState: 'unchanged'
    }
  },
  computed: {
    hasPrefixTitleActions() {
      return this._prefixTitleActions && this._prefixTitleActions.length
    },
    _prefixTitleSeparator() {
      return this.prefixTitleSeparator || this.options && this.options.prefixTitleSeparator
    },
    _prefixTitleActions() {
      return this.prefixTitleActions || this.options && this.options.prefixTitleActions
    },
    _title() {
      return this.title || this.options && this.options.title
    },
    _serviceSections() {
      return (
        this.serviceSections ||
        (this.options && this.options.serviceSections) ||
        []
      )
    },
    _notifications() {
      return (
        this.notifications ||
        (this.options && this.options.notifications)
      )
    },
    _account() {
      return this.account || (this.options && this.options.account)
    },
    _accounts() {
      return this.accounts || (this.options && this.options.accounts)
    },
    _currentLanguage() {
      return this.currentLanguage || (this.options && this.options.currentLanguage)
    },
    _languages() {
      return this.languages || (this.options && this.options.languages)
    },
    _theme() {
      return this.theme || (this.options && this.options.theme)
    },
    _i18n() {
      return this.i18n || (this.options && this.options.i18n)
    },
    translations() {
      return Object.assign({}, defaultTranslations, this._i18n)
    },
    showSaveBar() {
      return this.internalSaveBarState && this.internalSaveBarState !== 'unchanged'
    },
    hasClickTitleListener() {
      return Boolean(this.$listeners['click-title'] || (this.options && this.options.onClickTitle))
    }
  },
  created() {
    saveBarStore.addEventListener(this.onUiSaveBar)
    this.$watch('saveBarState', value => {
      if (value) {
        saveBarStore.setState(value)
      }
    }, {immediate: true})

    this.$watch('_theme', name => {
      const theme = themes[name]
      if (theme) {
        for (const [key, value] of Object.entries(theme)) {
          document.documentElement.style.setProperty(key, value)
        }
      }
      document.documentElement.style.setProperty('--ui-globalNav-height', '40px')
    }, {immediate: true})
  },
  beforeDestroy() {
    saveBarStore.removeEventListener(this.onUiSaveBar)
  },
  methods: {
    onUiSaveBar(event) {
      this.internalSaveBarState = event.detail.state
    },
    onChangeAccount(item) {
      this.$emit('change-account', item)
      if (this.options && this.options.onChangeAccount) {
        this.options.onChangeAccount(item)
      }
    },
    onChangeLanguage(code) {
      this.$emit('change-language', code)
      if (this.options && this.options.onChangeLanguage) {
        this.options.onChangeLanguage(code)
      }
    },
    onUiProgress(event) {
      if (event.subType === 'start') {
        this.progress = 1
        this.nudgeProgress()
      } else if (event.subType === 'done') {
        this.progress = 100
        setTimeout(() => this.progress = 0, 1000)
      }
    },
    nudgeProgress() {
      if (this.progress > 0) {
        const amount = getProgresNudgeAmount(this.progress)
        this.progress = Math.min(this.progress + amount, 99)
        const delay = Math.random() * 500 + 200
        setTimeout(() => this.nudgeProgress(), delay)
      }
    },
    onClickTitle() {
      this.$emit('click-title')
      const callback = this.options && this.options.onClickTitle
      if (callback) {
        callback()
      }
    }
  }
}

function getProgresNudgeAmount(progress) {
  if (progress >= 0 && progress < 20) {
    return 10
  } else if (progress >= 20 && progress < 50) {
    return 4
  } else if (progress >= 50 && progress < 80) {
    return 2
  } else if (progress >= 80 && progress < 99) {
    return .5
  }
  return 0
}
</script>

<style lang="scss" module>
@import '@/styles/variables';
@import '@/styles/mixins';
@import './common.module.scss';

.GlobalNav {
  --gNav-defaultTextColor: #232e3e;
  position: relative;

  &[data-theme="dark"] {
    .Logo__light {
      display: none;
    }
  }
  &[data-theme="light"] {
    .Logo__dark {
      display: none;
    }
  }
  &[data-theme="blue"] {
    .Logo__light {
      display: none;
    }
  }
}

.GlobalNav_bar {
  display: flex;
  align-items: center;
  height: var(--ui-globalNav-height);
  padding: 0 10px;
  background: var(--ui-globalNav-bgColor);
  color: var(--ui-globalNav-color);
}

.Logo__light, .Logo__dark {
  height: 35px;
  margin-left: 0;
  margin-right: 10px;
}

.TitleWrapper {
  display: flex;
}

.Title {
  font-weight: bold;
  font-size: 16px;
}
.Title__button {
  @include navButtonBase;
  &.Title__withLogo {
    margin-left: -12px;
  }
  .Title__icon__withLabel {
    margin-right: 8px;
  }
}
.TitleSeparator {
  width: 1px;
  height: 16px;
  background: var(--ui-globalNav-color);
  opacity: 0.7;
  align-self: center;
  margin: 0 2px;
}

.CurrentService {
  margin-right: 20px;
  font-weight: bold;
}

.Search {
  position: relative;
  flex: 1;
  // display: flex;
  display: none;
}

.SearchControl {
  position: absolute;
  top: -12px;
  width: 100%;
  max-width: 700px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.Search_icon {
  position: absolute;
  top: 6px;
  left: 10px;
  font-size: 14px;
}

.Search_input {
  flex: 1;
  display: block;
  width: 100%;
  padding: 5px 10px;
  padding-left: 32px;
  background: var(--ui-globalNav-searchBgColor);
  border: none;
  border-radius: 3px;
  // color: var(--ui-globalNav-searchColor);

  &::placeholder {
    color: var(--ui-globalNav-searchColor);
  }
  &:hover {
    background: var(--ui-globalNav-searchHoverBrColor);
  }
  &:focus {
    background: #fff;
    outline: 0;
    &::placeholder {
      color: #888;
    }
  }
}

.SearchDropdown {
  // position: absolute;
  // display: none;
  top: 100%;
  width: 100%;
  min-height: 250px;
  padding: 10px;
  background: #fff;
  color: #333;
}

.Right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.SaveBarFade_enterActive,
.SaveBarFade_leaveActive {
  transition: opacity 0.3s ease;
}
.SaveBarFade_enter,
.SaveBarFade_leaveTo {
  opacity: 0;
}

.ProgressBar {
  position: absolute;
  top: 0;
  width: 0px;
  height: 4px;
  z-index: 1;
  background: #1b73e8;
  transition: width 0.3s ease;

  [data-theme="light"] & {
    background: #28235F;
  }
  [data-theme="blue"] &,
  .hasSaveBar & {
    background: #0c53b3;
  }
}
</style>
