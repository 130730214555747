var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.GlobalNav,
    _vm.showSaveBar && _vm.$style.hasSaveBar
  ],attrs:{"data-theme":_vm._theme}},[_c('transition',{attrs:{"enter-active-class":_vm.$style.SaveBarFade_enterActive,"leave-active-class":_vm.$style.SaveBarFade_leaveActive,"enter-class":_vm.$style.SaveBarFade_enter,"leave-to-class":_vm.$style.SaveBarFade_leaveTo}},[(_vm.showSaveBar)?_c('SaveBar',{attrs:{"state":_vm.internalSaveBarState,"translations":_vm.translations.saveBar || {},"layoutMode":_vm.layoutMode},on:{"save":function($event){return _vm.$emit('save')},"discard":function($event){return _vm.$emit('discard')}}}):_vm._e()],1),_c('div',{class:_vm.$style.GlobalNav_bar},[(_vm.logo)?[_c('img',{class:_vm.$style.Logo__light,attrs:{"src":require("../../assets/logo-icon.svg")}}),_c('img',{class:_vm.$style.Logo__dark,attrs:{"src":require("../../assets/logo-icon-negative.svg")}})]:_vm._e(),(_vm.hasPrefixTitleActions || _vm._title)?_c('div',{class:[
        _vm.$style.TitleWrapper,
        _vm.logo && _vm.$style.TitleWrapper__withLogo
      ]},[(_vm.hasPrefixTitleActions)?[_vm._l((_vm._prefixTitleActions),function(action,index){return [(true === true)?_c('div',{key:("action-" + index),class:[
              _vm.$style.Title,
              _vm.$style.Title__button
            ],on:{"click":action.onAction}},[(action.icon)?_c('Icon',{class:[
                _vm.$style.Title__icon,
                action.label && _vm.$style.Title__icon__withLabel
              ],attrs:{"icon":action.icon}}):_vm._e(),_vm._v(" "+_vm._s(action.label)+" ")],1):_vm._e(),(_vm._prefixTitleSeparator && (index < _vm._prefixTitleActions.length || _vm._title))?_c('div',{key:("sep-" + index),staticStyle:{"display":"flex"}},[_c('div',{class:_vm.$style.TitleSeparator})]):_vm._e()]})]:_vm._e(),_c('div',{class:[
          _vm.$style.Title,
          _vm.hasClickTitleListener && _vm.$style.Title__button
        ],on:{"click":_vm.onClickTitle}},[_vm._v(_vm._s(_vm._title))])],2):_vm._e(),_c('div',{class:_vm.$style.Search},[_c('div',{class:_vm.$style.SearchControl},[_c('Icon',{class:_vm.$style.Search_icon,attrs:{"icon":"fa fa-search"}}),_c('input',{class:_vm.$style.Search_input,attrs:{"placeholder":"Search for services and resources by name"}}),_c('div',{class:_vm.$style.SearchDropdown},[_vm._v(" asddf ")])],1)]),_c('div',{class:_vm.$style.Right},[(_vm._serviceSections.length)?_c('ServicePicker',{attrs:{"sections":_vm._serviceSections,"i18n":_vm.translations}}):_vm._e(),(_vm._notifications)?_c('Notifications',{attrs:{"items":_vm._notifications,"i18n":_vm.translations}}):_vm._e(),(_vm._languages && _vm._currentLanguage)?_c('LanguageSwitcher',{attrs:{"languages":_vm._languages,"value":_vm._currentLanguage},on:{"change":_vm.onChangeLanguage}}):_vm._e(),(_vm._account && _vm._accounts.length)?_c('AccountSwitcher',{attrs:{"accounts":_vm._accounts,"value":_vm._account.accountId},on:{"change":_vm.onChangeAccount}}):_vm._e()],1),(_vm._account)?_c('AccountNav',{attrs:{"account-name":_vm._account.accountName,"user-name":_vm._account.userName,"user-email":_vm._account.userEmail,"avatar-url":_vm._account.avatarUrl,"sections":_vm._account.sections}}):_vm._e()],2),(_vm.progress > 0)?_c('div',{class:_vm.$style.ProgressBar,style:(("width: " + _vm.progress + "%"))}):_vm._e(),_c('EventDelegator',{on:{"ui-progress":_vm.onUiProgress}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }